var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"color":"element"}},[_c('v-card-title',{staticClass:"title-text"},[_vm._v("\n    "+_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.title")))+"\n  ")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"small-text pr-0 pb-0"},[_vm._v(_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.firstItem"))))]),_vm._v(" "),_c('v-col',{staticClass:"defaultText--text medium-text pl-0 pb-0",attrs:{"align":"right","cols":"8"}},[_vm._v("\n            "+_vm._s(_vm.$d(_vm.moment(new Date(_vm.product.subscriptionInformation.startDate)), 'short'))+"\n            - "+_vm._s(_vm.$d(_vm.moment(new Date(_vm.product.subscriptionInformation.endDate)), 'short'))+"\n          ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"small-text pb-0"},[_vm._v("\n            "+_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.secondItem")))+"\n            "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","size":14}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.prognose"))))])])],1),_vm._v(" "),_c('v-col',{staticClass:"defaultText--text medium-text pb-0",attrs:{"align":"right"}},[_vm._v("\n            "+_vm._s(_vm.$d(_vm.moment(new Date(_vm.product.subscriptionInformation.termEndDate)), 'monthYear'))+"\n          ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"small-text pb-0"},[_vm._v("\n            "+_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.thirdItem")))+"\n            "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","size":14}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.prognose"))))])])],1),_vm._v(" "),_c('v-col',{staticClass:"defaultText--text medium-text pb-0",attrs:{"align":"right"}},[_vm._v("\n            "+_vm._s(_vm.product.subscriptionInformation.termToMaturity)+"\n            "+_vm._s(_vm.$t((_vm.productId + ".productInfo.years")))+"\n          ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"small-text pr-0 pb-0",attrs:{"cols":"9"}},[_vm._v("\n            "+_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.fourthItem")))+"\n            "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","size":14}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.tooltipMessage"))))])])],1),_vm._v(" "),_c('v-col',{staticClass:"defaultText--text medium-text pl-0 pb-0",attrs:{"align":"right"}},[_vm._v("\n            "+_vm._s(_vm.$n(_vm.product.subscriptionInformation.historicalPerformance, 'float'))+" %\n          ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"small-text pr-0",attrs:{"cols":"9"}},[_vm._v("\n            "+_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.fifthItem")))+"\n            "),_c('v-tooltip',{attrs:{"top":"","color":"backgroundDark","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","size":14}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t((_vm.productId + ".productInfo.secondCard.tooltipMessageTwo"))))])])],1),_vm._v(" "),_c('v-col',{staticClass:"defaultText--text medium-text pl-0",attrs:{"align":"right"}},[_vm._v("\n            "+_vm._s(_vm.$n(_vm.product.subscriptionInformation.averageFees, 'float'))+" %\n          ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }