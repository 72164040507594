<template>
  <v-card
    class="rounded-lg"
    color="element"
  >
    <v-card-title class="title-text">
      {{ $t(`${productId}.productInfo.secondCard.title`) }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="small-text pr-0 pb-0">{{ $t(`${productId}.productInfo.secondCard.firstItem`) }}</v-col>
            <v-col
              class="defaultText--text medium-text pl-0 pb-0"
              align="right"
              cols="8"
            >
              {{ $d(moment(new Date(product.subscriptionInformation.startDate)), 'short') }}
              - {{ $d(moment(new Date(product.subscriptionInformation.endDate)), 'short') }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col class="small-text pb-0">
              {{ $t(`${productId}.productInfo.secondCard.secondItem`) }}
              <v-tooltip top color="backgroundDark" :max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :size="14"
                    class="ml-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t(`${productId}.productInfo.secondCard.prognose`) }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              class="defaultText--text medium-text pb-0"
              align="right"
            >
              {{ $d(moment(new Date(product.subscriptionInformation.termEndDate)), 'monthYear') }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col class="small-text pb-0">
              {{ $t(`${productId}.productInfo.secondCard.thirdItem`) }}
              <v-tooltip top color="backgroundDark" :max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :size="14"
                    class="ml-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t(`${productId}.productInfo.secondCard.prognose`) }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              class="defaultText--text medium-text pb-0"
              align="right"
            >
              {{ product.subscriptionInformation.termToMaturity }}
              {{ $t(`${productId}.productInfo.years`) }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="9" class="small-text pr-0 pb-0">
              {{ $t(`${productId}.productInfo.secondCard.fourthItem`) }}
              <v-tooltip top color="backgroundDark" :max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :size="14"
                    class="ml-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t(`${productId}.productInfo.secondCard.tooltipMessage`) }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              class="defaultText--text medium-text pl-0 pb-0"
              align="right"
            >
              {{ $n(product.subscriptionInformation.historicalPerformance, 'float') }} %
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="9" class="small-text pr-0">
              {{ $t(`${productId}.productInfo.secondCard.fifthItem`) }}
              <v-tooltip top color="backgroundDark" :max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :size="14"
                    class="ml-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t(`${productId}.productInfo.secondCard.tooltipMessageTwo`) }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              class="defaultText--text medium-text pl-0"
              align="right"
            >
              {{ $n(product.subscriptionInformation.averageFees, 'float') }} %
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SubscriptionTermsCard',
  props: {
    productId: {
      type: String,
      default: 'newDeals',
    },
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moment(date) {
      return moment(date);
    },
  },
};
</script>

<style>
.title-text {
  word-break: keep-all;
}

@media (max-width: 600px) {
  .title-text {
    font-size: 16px;
  }
  .small-text {
    font-size: 8px;
  }
  .medium-text {
    font-size: 9px;
  }
}
</style>
